<template>
  <div>
    <div class="d-inline-block ">
      {{ `${toPay} ${$currency}` }}
    </div>
    <div>
      <span class="mr-1 time-style mt-1">
        {{ $t('views.bookings.index.multiple.paid') }}:
      </span>
      <span class="red-text">
         {{ `${paid} ${$currency}` }}
      </span>
    </div>
  </div>
</template>
<script>
  import {toFloatFixed} from "@/utils/string";

  export default {
    props: {
      booking: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      toPay() {
        return toFloatFixed(this.booking.price);
      },
      paid() {
        return this.toPay - toFloatFixed(this.booking.restToPay);
      }
    }
  }
</script>
<style scoped lang="scss">
@import "@lazy/bookings/_multiple-booking-resume.scss";
.time-style {
  color: #a1a1a1;
}
</style>
